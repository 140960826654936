import { InvoicesStatsWidget, InvoicesStatsWidgetRequest } from '@app/models';
import { createAction, props } from '@ngrx/store';

export const loadStatsWidgetRequest = createAction(
  '[Invoices Stats] Load Stats Widget Request',
  props<{ data: InvoicesStatsWidgetRequest }>(),
);

export const loadStatsWidgetSuccess = createAction(
  '[Invoices Stats] Load Stats Widget Success',
  props<{ data: InvoicesStatsWidget }>(),
);

export const loadStatsWidgetFailure = createAction(
  '[Invoices Stats] Load Stats Widget Failure',
  props<{ error: any }>(),
);

export const reseState = createAction('[Invoices Stats] Reset State');
